<template>
  <div
    id="login"
    class="d-flex align-items-center justify-content-center position-relative"
  >
    <div>
      <div class="row login-content">
        <div
          id="tabuleiroIMG"
          class="col-md-7 position-relative p-0 imagem-login d-flex j-c-center"
        >
          <div class="card-text-login p-4">
            <p class="text-card-login">
              Aprenda online as <b>melhores</b> técnicas de xadrez para
              <b>dominar</b> a sua partida.
            </p>
          </div>
          <img
            class="powered-faster"
            src="@/assets/powered-by-fasters.png"
            alt="Desenvolvido pela Fasters Soluções em Tecnologia"
          />
        </div>
        <div
          class="col-md-5 d-flex align-items-center justify-content-center p-0 form-login-contato"
        >
          <div class="container">
            <div class="row login-content">
              <div class="col-md-12">
                <img id="logo" src="@/assets/logo-skaki.png" alt="logo.png" />
                <h2 class="mt-3 color-0e5caf"><b>Contato</b></h2>
                <div class="mt-4 mb-3">
                  <i class="fas fa-chess-knight"></i>
                  <span class="pl-2"><b>Aprimore suas habilidades.</b>
                  </span>
                  <br>
                </div>
                  <small class="text-nowrap">
                    Deseja falar conosco? Preencha os dados abaixo.
                  </small>
                <div
                  class="content d-flex align-items-center justify-content-center"
                >
                  <div class="row">
                    <div class="col-md-10 mx-auto py-3">
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <ValidationObserver
                          ref="form"
                          tag="form"
                          class="text-left"
                          @submit.prevent="sendForm()"
                        >
                          <div class="row">
                            <ValidationProvider
                              v-slot="{ errors, ariaMsg, classes }"
                              rules="required"
                              name="Nome"
                              :bails="false"
                              class="col-md-12"
                              tag="div"
                            >
                              <input
                                type="text"
                                :class="classes"
                                class="inputs-login"
                                placeholder="Nome"
                                v-model="objForm.name"
                              />
                              <div class="text-left">
                                <span v-bind="ariaMsg" class="span-status-validate">{{errors[0]}}</span>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider
                              v-slot="{ errors, ariaMsg, classes }"
                              rules="required"
                              name="Telefone"
                              :bails="false"
                              class="col-md-12 mt-3"
                              tag="div"
                            >
                              <input
                                v-model="objForm.telefone"
                                type="text"
                                :class="classes"
                                class="inputs-login"
                                v-mask="'(##) # ####-####'"
                                name="Telefone"
                                placeholder="Telefone"
                              />
                              <div class="text-left">
                                <span v-bind="ariaMsg" class="span-status-validate">{{errors[0]}}</span>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider
                              v-slot="{ errors, ariaMsg, classes }"
                              rules="required"
                              :class="classes"
                              name="'Mensagem'"
                              :bails="false"
                              class="col-md-12 mt-3"
                              tag="div"
                            >
                              <textarea
                                v-model="objForm.mensagem"
                                class="text-contato"
                                name="Mensagem"
                                id=""
                                placeholder="Mensagem"
                              ></textarea>
                              <div class="text-left">
                                <span v-bind="ariaMsg" class="span-status-validate">{{errors[0]}}</span>
                              </div>
                            </ValidationProvider>
                            <div class="col-md-12 mt-3">
                              <button
                                @click="$router.push('/login')"
                                class="button-login bg-0e5caf color-fff f-w-700"
                              >
                                Enviar
                              </button>
                            </div>
                          </div>
                        </ValidationObserver>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer class="auth-footer d-flex justify-content-between c-center">
            <p class="mr-5 text-secondary">
              {{ currentYear }} © Todos os direitos reservados - Projeto Skaki
              Xadrez
            </p>
            <!-- <i role="button" class="icones-footer fab fa-facebook-f"></i>
            <i role="button" class="icones-footer fab fa-facebook-messenger"></i> -->
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      objForm: {
        name: '',
        telefone: '',
        mensagem: '',
      },
    }
  },
  methods: {
    sendForm() {
      this.$refs.form.validate().then(success => {
        if (success) {
          $.ajax({
            type: 'POST',
            url: `${this.VUE_APP_CMS}api/exercicioUser`,
            data: objForm,
            dataType: 'json',
            headers: {
              Authorization: this.$store.getters.getPerfilToken,
            },
            success: response => {
              this.toastGlobal(
                'success',
                `Sua mensagem foi enviada com sucesso! Em breve nossa equipe entrará em contato.`
              )
            },
            error: response => {
              this.toastGlobal(
                'error',
                `Algo de errado ocorreu ao tentar enviar sua mensagem entre em contato com a equipe de desenvolvimento.`
              )
            },
          })
        }
      })
    },
  },
}
</script>

<style scoped>
@import '../style/telasDeLogin.css';

.text-contato {
  height: 7.1rem;
}

.linha {
  background: lightgray;
  height: 1px;
  width: 40%;
}

.centraliza-absolute {
  font-size: 27px;
  line-height: 1.8;
}

.form-login-contato {
  padding: 7% 7% 10% 7% !important;
}

#tabuleiroIMG {
  background-color: rgb(196, 222, 251);
}

#changeCpfCod,
#changeCodCpf {
  width: 40px;
  height: 40px;
  right: -55px;
  border-radius: 5px;
}

#changeCpfCod:hover,
#changeCodCpf:hover {
  cursor: pointer;
}
.button-login {
  border-radius: 14px !important;
}
.button-contact {
  border-radius: 14px !important;
  border: 1px solid #0e5caf;
}
</style>
