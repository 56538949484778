var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center position-relative",attrs:{"id":"login"}},[_c('div',[_c('div',{staticClass:"row login-content"},[_vm._m(0),_c('div',{staticClass:"col-md-5 d-flex align-items-center justify-content-center p-0 form-login-contato"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row login-content"},[_c('div',{staticClass:"col-md-12"},[_c('img',{attrs:{"id":"logo","src":require("@/assets/logo-skaki.png"),"alt":"logo.png"}}),_vm._m(1),_vm._m(2),_c('small',{staticClass:"text-nowrap"},[_vm._v(" Deseja falar conosco? Preencha os dados abaixo. ")]),_c('div',{staticClass:"content d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 mx-auto py-3"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('ValidationObserver',{ref:"form",staticClass:"text-left",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.sendForm()}}},[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-12",attrs:{"rules":"required","name":"Nome","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.objForm.name),expression:"objForm.name"}],staticClass:"inputs-login",class:classes,attrs:{"type":"text","placeholder":"Nome"},domProps:{"value":(_vm.objForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objForm, "name", $event.target.value)}}}),_c('div',{staticClass:"text-left"},[_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{staticClass:"col-md-12 mt-3",attrs:{"rules":"required","name":"Telefone","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.objForm.telefone),expression:"objForm.telefone"},{name:"mask",rawName:"v-mask",value:('(##) # ####-####'),expression:"'(##) # ####-####'"}],staticClass:"inputs-login",class:classes,attrs:{"type":"text","name":"Telefone","placeholder":"Telefone"},domProps:{"value":(_vm.objForm.telefone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objForm, "telefone", $event.target.value)}}}),_c('div',{staticClass:"text-left"},[_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{staticClass:"col-md-12 mt-3",class:_vm.classes,attrs:{"rules":"required","name":"'Mensagem'","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.objForm.mensagem),expression:"objForm.mensagem"}],staticClass:"text-contato",attrs:{"name":"Mensagem","id":"","placeholder":"Mensagem"},domProps:{"value":(_vm.objForm.mensagem)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.objForm, "mensagem", $event.target.value)}}}),_c('div',{staticClass:"text-left"},[_c('span',_vm._b({staticClass:"span-status-validate"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('div',{staticClass:"col-md-12 mt-3"},[_c('button',{staticClass:"button-login bg-0e5caf color-fff f-w-700",on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v(" Enviar ")])])],1)])],1)])])])])])]),_c('footer',{staticClass:"auth-footer d-flex justify-content-between c-center"},[_c('p',{staticClass:"mr-5 text-secondary"},[_vm._v(" "+_vm._s(_vm.currentYear)+" © Todos os direitos reservados - Projeto Skaki Xadrez ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-7 position-relative p-0 imagem-login d-flex j-c-center",attrs:{"id":"tabuleiroIMG"}},[_c('div',{staticClass:"card-text-login p-4"},[_c('p',{staticClass:"text-card-login"},[_vm._v(" Aprenda online as "),_c('b',[_vm._v("melhores")]),_vm._v(" técnicas de xadrez para "),_c('b',[_vm._v("dominar")]),_vm._v(" a sua partida. ")])]),_c('img',{staticClass:"powered-faster",attrs:{"src":require("@/assets/powered-by-fasters.png"),"alt":"Desenvolvido pela Fasters Soluções em Tecnologia"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"mt-3 color-0e5caf"},[_c('b',[_vm._v("Contato")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 mb-3"},[_c('i',{staticClass:"fas fa-chess-knight"}),_c('span',{staticClass:"pl-2"},[_c('b',[_vm._v("Aprimore suas habilidades.")])]),_c('br')])}]

export { render, staticRenderFns }